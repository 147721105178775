// import scrollToElement from 'scroll-to-element';
import vhCheck from 'vh-check';
import { loadGTM, enableTracking, disableTracking, sendPageView } from 'utils/tracking';

export const onClientEntry = () => {
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement('img');
  if (
    typeof testImg.style.objectFit === 'undefined' ||
    typeof testImg.style.objectPosition === 'undefined'
  ) {
    import('object-fit-images').then(({ default: ObjectFitImages }) =>
      ObjectFitImages(),
    );
    console.log('👍 Object-fit/Object-position are polyfilled');
  }

  // If the font does not load, have a fallback to show `broken` state (without right font) of site after 8 secs ~DH
  setTimeout(() => {
    document.querySelector('html').classList.add('forceShowPage');
  }, 2000);

  // Check viewport height.
  // Sets --vh-offset that can be used in styles to account for mobile browser address bar offset.
  if (typeof window !== 'undefined') {
    vhCheck();
  }
};

export const shouldUpdateScroll = () => true;

export const onInitialClientRender = () => {
  // Enable tracking if user is okay with it.
  //loadGTM();
};

export const onRouteUpdate = ({ location }) => {
  // Send page view when location changes.
  sendPageView(location);
};

// export const onRouteUpdate = ({ location: { hash } }) => {
//   console.log({ hash });
//   if (hash) {
//     window.setTimeout(() => scrollToElement(hash), 10);
//   }
// };
