import cookie from 'react-cookies';

/**
 * Enable tracking.
 * Loads google analytics.
 *
 * Used in gatsby-browser.js onInitialClientRender
 */
 export function loadGTM() {
  if (window && document) {
    const gaId = process.env.GATSBY_GOOGLE_ANALYTICS_ID;

    // Init tracking api (I guess).
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    window.gtag = gtag;

    gtag('start', new Date());
    gtag('js', new Date());
    gtag('config', gaId, { anonymize_ip: true });

    // Load script.
    const gascript = document.createElement('script');
    gascript.async = true;
    gascript.src = `https://www.googletagmanager.com/gtag/js?id=GTM-WRJP6WJ`;

    document
      .getElementsByTagName('head')[0]
      .appendChild(gascript, document.getElementsByTagName('head')[0]);
  }
}

/**
 * Enable tracking.
 * Loads google analytics.
 *
 * Used in gatsby-browser.js onInitialClientRender
 */
export function enableTracking() {
  if (window && document && cookie.load('accepted_cookies') === 'true') {
    const gaId = process.env.GATSBY_GOOGLE_ANALYTICS_ID;

    // Init tracking api (I guess).
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      dataLayer.push(arguments);
    }

    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', gaId, { anonymize_ip: true });

    // Load script.
    const gascript = document.createElement('script');
    gascript.async = true;
    gascript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;

    document
      .getElementsByTagName('head')[0]
      .appendChild(gascript, document.getElementsByTagName('head')[0]);
  }
}

/**
 * Disable tracking.
 * Set typical google disable cookie.
 */
export function disableTracking() {
  if (window && document) {
    const disableStr = `ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`;
    window[disableStr] = true;
    document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
  }
}

/**
 * Send page view.
 * Used in gatsby-browser.js onRouteUpdate
 *
 * @param {*} location
 */
export function sendPageView(location) {
  if (!window || typeof window.gtag !== 'function') return null;

  window.gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
    page_path: `${location.pathname}${location.search}${location.hash}`,
    anonymize_ip: true,
  });
}
