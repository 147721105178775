import React, { useContext, useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { parseAsContent } from 'utils/parser';
import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll';
import isLegalPage from  'utils/isLegalPage';

import Link from 'components/common/Link';
import { CookiegateContext } from 'components/container/Cookiegate';

import {
  CookiegateStyled,
  InnerStyled,
  IconStyled,
  ContentStyled,
  HeadlineStyled,
  TextStyled,
  OptInLabelStyled,
  OptInInputStyled,
  ButtonWrapStyled,
  ButtonAcceptStyled,
  FooterStyled,
} from './CookiegateStyled';

function Cookiegate({ data }) {
  const {
    globalCookieGateHeadline,
    globalCookieGateText,
    globalCookieGateFooterLinks,
    globalCookieGateYes,
    globalCookieGateOptInTrackingText,
    globalCookieGateDeactivate,
  } = data;

  const { acceptedCookies, handleAcceptClick } = useContext(CookiegateContext);
  const [trackingOptIn, setTrackingOptIn] = useState(false);

  const showCookiegate = !acceptedCookies && !isLegalPage() && !globalCookieGateDeactivate

  useLockBodyScroll(showCookiegate);

  // Only show restrictions prompt when cookies got loaded and the values are "false". ~RS
  return showCookiegate ? (
    <CookiegateStyled>
      <InnerStyled>
        <IconStyled type="logoWarsteiner" />
        <ContentStyled>
          <HeadlineStyled>{globalCookieGateHeadline}</HeadlineStyled>
          <TextStyled element="div">
            {parseAsContent(globalCookieGateText?.content)}
          </TextStyled>
          <OptInLabelStyled htmlFor="opt-in-tracking">
            <OptInInputStyled
              type="checkbox"
              id="opt-in-tracking"
              onChange={() => setTrackingOptIn(!trackingOptIn)}
            />
            {parseAsContent(globalCookieGateOptInTrackingText?.content)}
          </OptInLabelStyled>
        </ContentStyled>
        <ButtonWrapStyled>
          <ButtonAcceptStyled onClick={() => handleAcceptClick(trackingOptIn)}>
            {globalCookieGateYes}
          </ButtonAcceptStyled>
        </ButtonWrapStyled>
      </InnerStyled>
      {globalCookieGateFooterLinks && (
        <FooterStyled>
          {globalCookieGateFooterLinks.map(({ id, fullUri, title }) => (
            <Link key={id} to={fullUri}>
              {title}
            </Link>
          ))}
        </FooterStyled>
      )}
    </CookiegateStyled>
  ) : null;
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query CookiegateQuery {
        craft {
          globals(siteId: 5) {
            global {
              globalCookieGateHeadline
              globalCookieGateText {
                content
              }
              globalCookieGateOptInTrackingText {
                content
              }
              globalCookieGateYes
              globalCookieGateFooterLinks {
                id
                fullUri
                title
              }
              globalCookieGateDeactivate
            }
          }
        }
      }
    `}
    render={(data) => (
      <Cookiegate data={data?.craft?.globals?.global} {...props} />
    )}
  />
);
