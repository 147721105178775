import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import Image from 'components/common/ImageGatsbyCraft';
import Icon from 'components/common/Icon';

import {
  LinkStyled,
  FooterStyled,
  FooterSocial,
  FooterSocialList,
  FooterSocialItem,
  FooterSocialLink,
  FooterTopStyled,
  FooterMenuStyled,
  FooterListStyled,
  FooterListWrapperStyled,
  FooterListItemStyled,
  FooterBottomStyled,
  FooterDecoWrapStyled,
  FooterDecoItemLeftStyled,
  FooterDecoBottleSpacerStyled,
  FooterDecoItemCenterStyled,
  FooterDecoItemRightStyled,
  FooterDecoItemBottleStyled,
  FooterCopyrightStyled,
  FooterGroupLogoStyled,
} from './FooterStyled';

function Footer({
  navMenuItems,
  socialListItems,
  brandCopyrightTitle,
  brandGroupLogo,
}) {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterStyled>
      <FooterTopStyled>
        <FooterDecoWrapStyled>
          <FooterDecoItemLeftStyled />
          <FooterDecoItemCenterStyled>
            <FooterDecoBottleSpacerStyled />
            <FooterDecoItemBottleStyled>
              <Icon type="warsteinerBottleOutline" />
            </FooterDecoItemBottleStyled>
          </FooterDecoItemCenterStyled>
          <FooterDecoItemRightStyled />
        </FooterDecoWrapStyled>

        {Boolean(socialListItems) && (
          <FooterSocial>
            <Grid>
              <GridItem>
                <FooterSocialList>
                  {socialListItems.map((socialItem, i) => (
                    <FooterSocialItem key={socialItem.id || i}>
                      <FooterSocialLink
                        target="_blank"
                        href={socialItem.socialLink}
                      >
                        <Icon type={socialItem.socialIcon} />
                      </FooterSocialLink>
                    </FooterSocialItem>
                  ))}
                </FooterSocialList>
              </GridItem>
            </Grid>
          </FooterSocial>
        )}

        {Boolean(navMenuItems) && (
          <FooterMenuStyled>
            <Grid>
              <GridItem>
                <FooterListStyled>
                  <FooterListWrapperStyled>
                    {navMenuItems.map((navItem, i) => (
                      <FooterListItemStyled key={navItem.id || i}>
                        <LinkStyled
                          to={navItem.link?.entry?.fullUri || navItem.link?.url}
                          target={navItem.link?.target}
                        >
                          {navItem.title || navItem.link[0].title}
                        </LinkStyled>
                      </FooterListItemStyled>
                    ))}
                  </FooterListWrapperStyled>
                </FooterListStyled>
              </GridItem>
            </Grid>
          </FooterMenuStyled>
        )}
      </FooterTopStyled>
      <FooterBottomStyled>
        <Grid between>
          <GridItem mobile={0}>
            <FooterCopyrightStyled>
              &copy; {year}{' '}
              {Boolean(brandCopyrightTitle) && brandCopyrightTitle}
            </FooterCopyrightStyled>
          </GridItem>
        </Grid>
      </FooterBottomStyled>
    </FooterStyled>
  );
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        craft {
          globals(siteId: 5) {
            globalFooter {
              globalFooterSubNav {
                ... on Craft_GlobalFooterSubNavFooterSubNavItem {
                  id
                  title: footerSubNavItemTitle
                  link: footerSubNavItemLink {
                    entry {
                      fullUri
                    }
                    url
                    customText
                    defaultText
                    target
                  }
                }
              }
            }
            globalSocial {
              globalSocialList {
                socialTitle
                socialIcon
                socialLink
              }
            }
            globalBranding {
              globalBrandTitle
              globalBrandGroupLogo {
                url
                ...CraftImageFluid
              }
            }
          }
        }

        bottleFooter: file(
          relativePath: { eq: "warsteiner-bottle-footer.png" }
        ) {
          ...imageDefault
        }
      }
    `}
    render={(data) => (
      <Footer
        navMenuItems={data?.craft?.globals?.globalFooter?.globalFooterSubNav}
        socialListItems={data?.craft?.globals?.globalSocial?.globalSocialList}
        brandGroupLogo={
          data?.craft?.globals?.globalBranding?.globalBrandGroupLogo?.[0]
        }
        brandCopyrightTitle={
          data?.craft?.globals?.globalBranding?.globalBrandTitle
        }
        {...props}
      />
    )}
  />
);
